import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card, CardImg, CardText, CardTitle } from 'reactstrap';
import { connect } from 'react-redux'

const Example = ({ props, lang }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Button onClick={toggle} style={{ marginBottom: '1rem' }}>{lang === 'english' ? "More about Ibis and Hunt" : "Más de Ibis y Hunt"}</Button>
            <Collapse isOpen={isOpen}>
                <Card>
                    <CardBody>
                        <CardTitle><h4 style={{ fontWeight: 'bold' }}>{lang === 'english' ? "About Ibis" : "Más de Ibis"}</h4></CardTitle>
                        <CardText>{lang === 'english' ? "Ibis grew up with her younger brother Pepe, mom Ibis and dad Pepe in Villahermosa. She went to the university in Mérida, Mexico, where she studied art and design. She is passionate about designing clothes, swimsuits, bags, jewelry and accessories. She practices graphic design and photgraphy too. In her free time she loves to draw and dance."
                            : "Ibis creció con su hermano menor Pepe, mamá Ibis y Papá Pepe en Villahermosa. Fue a la universidad en Mérida, Yucatán, donde estudió Arte y Mercadotecnia en Diseño. Su pasión es diseñar ropa, traje de baño, joyas, bolsas, pañaleras y accesorios. Otra de la cosas que ama es el diseño gráfico y la fotografía. Le encanta dibujar y bailar en su tiempo libre."}</CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle><h4 style={{ fontWeight: 'bold' }}>{lang === 'english' ? "About Hunt" : "Más de Hunt"}</h4></CardTitle>
                        <CardText>{lang === 'english' ? "Hunt spent his childhood in Charleston, SC with his older sister, Emily, his mom Buffy, and dad Arthur. He grew up with the ocean and the beach. He graduated from Clemson University with a business degree. With a love for mountain acitivities like fly fishing, skiing, and camping, Hunt decided to move to Denver, Colorado. He is currently working as a software engineer, building mobile apps and web sites like this one! His sister Emily recently moved out to live in Denver."
                            : "Hunt pasó la mayor parte de su infancia en Charleston, SC, con una hermana mayor Emily y su madre Buffy y su padre Arthur. Asistió a la universidad de Clemson, dónde se graduó con un título en negocios. Con un aprecio por las montañas y una pasión por el esquí y los campamentos, Denver, Colorado, sonaba como el lugar correcto. Él construye y diseña sitios web como este sitio y aplicaciones móviles. Su hermana Emily recientemente se mudó a vivir a Denver también."}</CardText>
                    </CardBody>
                </Card>
            </Collapse>
        </div>
    );
}

export default connect(state => ({
    lang: state.lang
}), null)(Example)