import React from 'react';
import Gallery from 'react-grid-gallery';


const Example = ({ album }) => {

    const IMAGES = []

    album.map(pic => {
        return IMAGES.push({
            src: pic.big,
            thumbnail: pic.small,
            thumbnailWidth: pic.width,
            thumbnailHeight: pic.height,
            caption: pic.description
        })
    })

    const marginFix = () => {
        return ({
            cursor: 'pointer',
            height: '180px',
            marginLeft: '0px',
            marginTop: '0px'
        })
    }

    const bg = () => {
        return ({
            backgroundColor: '#FFFEF2'
        })
    }

    return (
        <>
            <Gallery backdropClosesModal={true} enableImageSelection={false} thumbnailStyle={marginFix} tileViewportStyle={bg} images={IMAGES} />
        </>
    )
}

export default Example

//cuts
//{document.getElementById('example-0')}

// thumbnailStyle={this.styleSmall}

// thumbnailStyle={marginFix}