import React, { Component } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from '../components/photo-grid'
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import styles from '../styles/layout.module.css'
import Collapse from '../components/collapse'


const album = [{
  big: 'https://lh3.googleusercontent.com/HqI4SM7bLkm8UUXGC_3sBi4uxQb-Os3MWWWHVx-t6FPlKtdH93V49jpPny8lHKgIFLJoOhvE411YqrOmLqoULFePrTr5B7k-OQomm1wn2QlESakKKUBqEvORyWtBI54cYtBq8KaIVxk=d',
  small: 'https://lh3.googleusercontent.com/AtnYZGqhI1EYgyMQDcLUeGa_AN9aCr1iW8tREbYv-67wO2c85jgcxcdGdZvnByFfwGJibBNwWfJ8Du8Jtg9jworliGeB8sKaXuPDc3lBVPIpM8KJ2JwAnwvWuSP55xqfEmDIfHw0Gt8=d',
  width: 280,
  height: 272,
  caption: 'Yumka'
},
{
  big: 'https://lh3.googleusercontent.com/MNH_HjzJ2KN0KZOD3VgFYxyI4q7GtQ9D-2Af-QjMIihIm4UHzIQEGcuRb7IoEuqqhmF4WXClybvO5_pxtpuOgHaVrYhsJw7KP-NdkYLKSPSojMZkXigOWoMLNp3YenhWA61RCbQgU-w=d',
  small: 'https://lh3.googleusercontent.com/EFYJJDPZ2ldetq3sIZldlPNILsM6uZt4vgZm-X3oPbgIsUxKk48UyuZnmAgmDFnIKHJz2MUpmxXdrehed2XhG1VZ8WlcPvy61LECP1JOpUALBJKk4yCVGmohnYpkYvCWmyUTOSVGbLY=d',
  width: 210,
  height: 280,
  caption: 'Eating'
},
{
  big: 'https://lh3.googleusercontent.com/Or0VQlSLGEpz3xyPI0u3YQLCOpznRozisFO-WL7gpsi7mN2oEDib2VY-u8KySiD0wRXd4dI0AeTP8zVsv4LeaifK_8vfHW8ulsFLvTezVtNBqVg_sAJ3f15APYVczr7k4D-Q_KJPC8M=d',
  small: 'https://lh3.googleusercontent.com/BOXW3iUz_sbNY-u2VCC3H5-i8uJHn5vvwPMbmrBIY8VRODL9rXRJ0x4YNkycW5Xo6fcb0za_zrgHtSt2eMeB789xgRq5a_l6OsxaCuIWkl4cNnlTXw2TD6pl6xBsnb2xj4xl4l_n0-I=d',
  width: 210,
  height: 280,
  caption: 'Mtns2'
},
{
  big: 'https://lh3.googleusercontent.com/eVmu0PeH95KfvuEOpP0YCWhHvsRQ1AndjntEzUcDCGPuqVAwkjaS4ymG1ep8d5pf1MLxlx0xyadmUo07kk8zpD3xyAubcO7ifq0BW9h6kZi5N_tiKLGReUxek03Tesx8y4JrG8XVgCg=d',
  small: 'https://lh3.googleusercontent.com/xI-iEcZJLi1Lh7Va3zdP2ZgryCGKEHF0xI25k2JhfkbMZLXlIgvE9izOPEmcWaoejRbHqpq9l6YtvNGE0_ST6HZG1nJpiuN_NkdcyE50iHs14U7Ir-MQak1wDXhRH9u5gxQJquBfk6U=d',
  width: 251,
  height: 280,
  caption: 'Golfing'
},
{
  big: 'https://lh3.googleusercontent.com/iy2wXN79l1MqU5aTJyjje1kM5O9XEqovQnwsYw24_n_l5nOApnKn2zB-FAw0bQYHvb6xNwyEg35FfC7N5nSdbAarmZv5JcJWqBTmd1s8pXWLmXVly5QL37wRTnPqBRlAQm4Dm_PjHV4=d',
  small: 'https://lh3.googleusercontent.com/pZb5GBFfkyW3kl9YKq4yIOS3Nk5huC2CbGozvDyTWyzs6MsYVtpHCnB4cDWp71FtWuE-1bYxSR5iX9AmAoSOkMmXvKo8NZDIWp09Wa0k1JkWDDVTqFGG17ddxRs4-Lmwq4FIWMtPgt8=d',
  width: 231,
  height: 280,
  caption: 'Tapi'
},
{
  big: 'https://lh3.googleusercontent.com/QfmtA1w4-goqNAUHS3gb25pZum3SsgwgcvlwUnllR94JFyfpnglkhtKgKGelovs19X_S7TkM8z2wEKt_WXA3mcjQwQqJIYxl3qPjXI3D-8jjbBHzh-cWuk7yNdFsgHozt_20rPWzG8Q=d',
  small: 'https://lh3.googleusercontent.com/0th-YR4z1rxyTvjAOUcwfI_bzFliQiaBf7SHYSAEmQPVXUcTvK1LwxirSrm07Npy07DjRmZ3yDjwpuuzSZQa26XgbZN57Dq4omTDYF6QSCGdrK6B0PkmIOQz897Cmytx12ygP0O5eVg=d',
  width: 280,
  height: 195,
  caption: 'Dad'
},
{
  big: 'https://lh3.googleusercontent.com/vaypRNGlb1jSiQ_BnDlXWNj8MmU4VtrJ9oi_c_hRhA34xO4YTFVEzdY3W5nylnO5Wpf6mxg2osRx7L6O_p7dZtQY5SIvzgCpZ5W1ixZqqNDS26CrzZu1FOVozijp7OHlsN-ywR0FPB4=d',
  small: 'https://lh3.googleusercontent.com/7om7ZK2xFiKLjBy7zl0EA3r36TOpXqig95KDOSZhde1pdvUGL5tARSQnMzshfR_P4MaR3ahg0fRKjHJ4whef_EC9rI5fsCwBgzCkx4yUgaHY58rOFV-RxjcJoEh3HjiWohImU_H7a3o=d',
  width: 280,
  height: 222,
  caption: 'Baseball'
},
{
  big: 'https://lh3.googleusercontent.com/b52bd2Ib5hg2I9XbHvj1lP4dVjsgGQLXrMHFx2L0olDX3lCgx1SUYuCRCZVmhfLDimcmWD61piY9yoYAZxP6bQ8sB_W21SBIFlYl-Pc_c2--3S0aXp7PAPp8gOuKM_0hJxws9NbPigw=d',
  small: 'https://lh3.googleusercontent.com/mcRMophC2ruHVb6JmOv3vn3n0sZkbXxPfKetn2Q-IfO6aOwtXAq8UoET8Vv2U1ROtfnLJkEo2d6g-oPzsAy-EwhikI4pE6kNQO1wekIO_2rQ34NexyOE9C_OMNBZ_yW6qvJmp_IB89k=d',
  width: 209,
  height: 280,
  caption: 'Centro'
},
{
  big: 'https://lh3.googleusercontent.com/tu7z29W_q-uRq_-93-K_eEQ7QaZyA0DMzj_IXJX82kkg58nPSK7tOcozzBNna1Mil0iUZz5bFQV-Jq57TqbCDHiMXxkjdmsG0I7wkpkY10wgaUfo2EnsoHelsVrZWlUbbC7i2oZ3oYg=d',
  small: 'https://lh3.googleusercontent.com/bCycDtjA1mSGmgnHDBA73Yi_2wXLnM8Clzai0sZKsv3dQB3NsgTcrfFJI45DoMAd2pGxk8mOEq1eV5zDiSRJCtrSO-CSQ6-e4sYvpNqcgSN6CZM3ooBRV5bLSaUNA0FM6GS3x0gpLN4=d',
  width: 280,
  height: 199,
  caption: 'Fern'
},
{
  big: 'https://lh3.googleusercontent.com/gsQO-_BH-SHESRtWNzYRxrZWBg-_t-PKaaK0gP6aHTYQ26nRJJWKmSMUn3b6uJUYmUiTXzGc1M6Yz-6YwH_syQwuj9s76iOz-VuDLhJJvrGs6KKUy83Ow6sd5NP7pbpBAmcVF-0xdy0=d',
  small: 'https://lh3.googleusercontent.com/uBGyWoylEwEhuio10R9NWDt9XgPWVxinc0WXL_xfRPOxDFu2DqNMEn4sxnpX-uqDuYcZgZd99E7s1tODTt-81HMNfaBgpnyo5qY3yh_2WdSRq4WQSzH99Y41kNCf_5dazbre1p9HMqM=d',
  width: 280,
  height: 145,
  caption: 'Granny'
},
{
  big: 'https://lh3.googleusercontent.com/-MhQs15Y0sY3oBLDljFyBRMkAgYSqQfWT8fev6U9-UzJoXy3k177TbVbmIOKSKRQ0Bgwr-_FxCH7qNC6qcP2U0fCd2CXLTJGEEfeB_S29jU8r7-1XDvoHifKAcNFOpc9RgvYlD-aF-M=d',
  small: 'https://lh3.googleusercontent.com/axKDAlwo8MrJ1gYP8p1g4m2DRZmz2hun0LCbcbZ5HMWOei29vXdP8tkkFmPtLM1ozT6B74-uMwtcRfRkC442QWZncKqOUNhy7kgNug3-sCPu0oQji3MatbNac7iyGp5Ba0BceMX2R_A=d',
  width: 237,
  height: 280,
  caption: 'Yellow'
},
{
  big: 'https://lh3.googleusercontent.com/Gxcjsh-EKnrq-F4w8BOSRkosaMMBemGUk1smXfvonvxhYL6ynKW7BAc9CaGZWaFkMLbZ1Rm4TsdGLhAWneg1jg1fa8-W4bGQPjpBT690SSR2PPqZloZiiJvR-_sxEeEu1VZwCS5bxJM=d',
  small: 'https://lh3.googleusercontent.com/TgbXNbk-GiOvLon1VwNCJVtYEBs3G_riOJ5EJHXukAC1A5yfSWME-jT67WiIvt7PM1-VfZo-lLL7__PcfYFuyAtxxCfzBx-aor1TlHzVysJUEq1krN9K6l_9u71J-k9-AS69MYu2Ae8=d',
  width: 280,
  height: 210,
  caption: 'Abud'
},
{
  big: 'https://lh3.googleusercontent.com/pLJmdU74FuELJ86CvBXcB4UIB_jga9aaV_ym8E5Cohb5752BbWXxvEya-EEVReILMefaTPOQX2XjOuosBOtLrUi2hspEYrtfCIWVD9yhH8YbCMMaylSzBRtAFlX-tl824LQ6hhYSdG4=d',
  small: 'https://lh3.googleusercontent.com/BvzLmrtHUsxBDZiesq1yR4RNVJmaDb79maS0nsNNc8CEcVPJGI-rqPixxfuifOFbRLKpCg5pBS-WsiIjHfZPk8oQ1gs2nNKb9ksbg_Y26UtV4_BSFpiaEqM6lm5MRy9RxUxIu-yzVOM=d',
  width: 210,
  height: 280,
  caption: 'Tyler'
},
{
  big: 'https://lh3.googleusercontent.com/ae8T-QTc65fgyv0PL1i4mZfYg91-3o5kC4_vrPzSf6fx2BOWvRdWQa1MmhNOMVe1Pa-ecr2C-ebmTd_zkf-CFOv9mu9hZ7u8cj31wCvmoCxfgY2zM10e8AY6SarLd0dHBcLq1Z6VAHY=d',
  small: 'https://lh3.googleusercontent.com/B0-yWH0NiKTxI7x5PPuAh9d_uBJ2gAYxINpwRUi2oLL8XwFUBRsaBAUuIsX1V2-cIS3kIoXeuDj-zbBuUV9IBazzrrfW_RQssiankJRdBjJnrkxFrcUFoJJIDA-R_kcC6k8wbElHhvo=d',
  width: 280,
  height: 155,
  caption: 'Techa'
},
{
  big: 'https://lh3.googleusercontent.com/w0zuYJwQG6Bxuo4WuDiZt7WhzBfuReWTbs0NWa6ypMKGRBh4tJOhTU3SXEUcgxzCGo3mBpsJYC-3pI13f6nXM9WO21HTXRSVUa-0eNi9HPFLwXffTTJHhRkZ9c42epLv2jr9VJLMn3c=d',
  small: 'https://lh3.googleusercontent.com/bitSC0FvBoduybWeVsdDNMnMa0x3i6yMIWggMJvTdEGILSpD01zDzmXuX_qHfPtiGFomxMrAdvArUtr96rgb0rTM9PdNL2tjctu68CiD51VZwfOU5z_Xfn9VF-OsTI8x5m-mG82SFJE=d',
  width: 280,
  height: 244,
  caption: 'Romantic'
},
{
  big: 'https://lh3.googleusercontent.com/-jBMQQrHmiOw3ZanHk3o6G1Tz2z_rCUHzlEm8K8FRNu903LuUrsHThl3M4ZcP4M8hopXMNH3etMR_XUUFVmNHV-GavmL_HTaLnJD_US_BfU_1SGAWKrhlM5JEDlI7IaXlLEFFLbHi8o=d',
  small: 'https://lh3.googleusercontent.com/-CuezEStbdxXUrFrBh7LoLr0G4DzAbER1hM-QFKjGuCBIJOqc3mV8-nfleCLCNr4TBP8GmuJyfeP1ZN3W2QCVuqfY7yIhU1PIwsmgCeTsm445wq6v5NBSzeM14hLvEcsHHUuodD-Klw=d',
  width: 280,
  height: 219,
  caption: 'Louisville River'
},
{
  big: 'https://lh3.googleusercontent.com/VENPdWsOsCBZs5EZWeDJj7d0NoPJGVhlRUYgWTIsBlu1yUwNgvvABoaIie13xuOMm1N3QskmusCs60oqyq6jiqtidJcUFtnI3xDLfwTM1tjsnm_kWthKUxvO2JW8XpGOqu5Eismkxrw=d',
  small: 'https://lh3.googleusercontent.com/WrrD0IWwagY_s0rxIr1bx7OV69cquv3JKOWvYDWIrJF3qqIHjYautaplEEQ66vBg98NTgtt9agKvlqTFtzTjz1W1cKQrjLSgiGc4OcarpnIoVbpygP7i4gm-BNnUtII_sezKevMzq_A=d',
  width: 149,
  height: 280,
  caption: 'Mom'
},
{
  big: 'https://lh3.googleusercontent.com/G9DBNkdKKBgxSEMpWUln-BPVfrvMBygB7yled3EqwSKR6ICAiaiUe0NdLO5S5kdz5Ylt_mK8VibU3fnLNchLqZECD7qaAe67fMckpygb-Hw0KDMzEwU-v3MsQejd9HZADSyzpxup3es=d',
  small: 'https://lh3.googleusercontent.com/Dj9X83HtS0h-OYszbJrcu-UtXqLelFatAaOEleo2kDRu3z2Ye6-4qR8Nk3dvvUgKar88k9P--qdIUiX2SAevgGmAtiJ6jMeOokJh1vBdtoG_ATiX4CN-xraiQLlWT424ysHEPwvnDnc=d',
  width: 280,
  height: 152,
  caption: 'Grandaddy'
},
{
  big: 'https://lh3.googleusercontent.com/-vJ38KSTMTqHLQIR1vYmRArG50wSQyCkaPYsXK6JiCRodH7ibqc9PfuXZ_ng3j-d_jgPdnWTAH_dYwy5-7did5N-V8ASkM8ho0_tTuEqNzJft4VQOlY0Ad-8C0x604kQtRUrAnn_PGA=d',
  small: 'https://lh3.googleusercontent.com/Izfv-t7xASyXvhc-udmpP7ONfWvw1PB0_gn6AXzBXVqq61uq__vqVngLhmFEC72h00n53Y0NqFt_j4F6dvNCBKSgoCcOoR8iFVrYchqoI3g7z8kh357ZOvOnpoHrG1ByBvoQ6y2SCFE=d',
  width: 175,
  height: 280,
  caption: 'Bridge'
},
{
  big: 'https://lh3.googleusercontent.com/rwGVnlrzn94kqvYSzL3dpHAwvrkTPt7OSU-m1btgHMekvpwJFTnTFWkVZKTZZ8DUhM7p8ssDe1_M0FQ1ChLpJf8bRmNly4454TrdpHMCWBnc9HwKeB0MtrucoVQvgOHfxinia3iob0c=d',
  small: 'https://lh3.googleusercontent.com/PZj-8tux2ug7m9n1Y6RHetuotKqU-9H7apiMSUkRUcMSb6ikLzFHh05AmgSv3Sdp5Q0Be6RFIDgCDHZ8hYYmVskj3yTxK4ciEM94zHTrjuh2uDfzEco1Aw205R8kKs_XfH9NaIw27xA=d',
  width: 271,
  height: 280,
  caption: 'Granny and Ibis'
},
{
  big: 'https://lh3.googleusercontent.com/6E2wKBkvYZI3YXzOkuExY2yCkS-eZ9DwjPBVyNDG7fkpmw1323lOhmYBDanlZb3sXTtPjzGj0xA3Kzbwt72kqJQLicZM8OG6pHx3fqg0-OOE74vVxDMi4AN2_tiVv5gaRaKnxgyJMWY=d',
  small: 'https://lh3.googleusercontent.com/LiNvLY0Qu3HV5T_u6OXrNLonGTuHJjxmj3hX0qyPMjd6ABlPjchHC3EeZEzI5B7Kkz39rjGlrMKvB7eIvWlmeHd0X0ofw6XaT98tk1Qm5qCFiwi4mSU7AS4OMDkIkuJT6cPEwF7rnEo=d',
  width: 197,
  height: 280,
  caption: 'Views'
},
{
  big: 'https://lh3.googleusercontent.com/SQvQlVxRNVaygE5r997nv9_ekXCi7W0CRuWhAaT1RPqWlW9bKOTtrr_N8WBQwxZJ6NA1bC5jLTkT0wpqEqgc71eYP9hHW6tqL5IABousNc7qMzIjj-VWYyVZwuc_SH74dhmjY89-8ds=d',
  small: 'https://lh3.googleusercontent.com/G1gAfoID1NtZD1rQ66WQVGCPlpclB_oCz40J0APjFpJ5f73VZRstE__bOyM3KtdKSVPZ6Kbac9azAVYtAFvVku1novER9Y8r5uM5glKPks4LKHgwSU2FvFPibV-N0OuU3fBZ0iUs4vQ=d',
  width: 280,
  height: 206,
  caption: 'Kissing'
},
{
  big: 'https://lh3.googleusercontent.com/xiqPX27STRWLm1J5Exrp7KxBwDjmIIYOtSqXu6FuM2sjEeIntjrfL6_Ev8IciCzXDPp2RfCK8obaD-bFanqF3jE5_9A4_BZeafuGCPqPRhoKN20qmlqFi11rEFaQ7b5nkcr5nlvdlyc=d',
  small: 'https://lh3.googleusercontent.com/4qHfjbdGeSEm6w7FTHnczU3n3lGR-6_pwd1dUA1UblYb9urUaS40J_PbJvusc73x9T9rGwkvHb5zCrsYsRQCXy-gmCxkPC7XrlAUHQDut-gGcyHim4d7A1S4hXIdO2bwgsHiIV-mgM0=d',
  width: 210,
  height: 280,
  caption: 'Promise'
},
{
  big: 'https://lh3.googleusercontent.com/RZbgT5q039XAoPW-m6ANbc-v6EaW9YkOLB3X2ThIlr-zSBCRSHPx1z0wbOi7D9b1-MXpEtY1TNUEOG0w0p_48uNjg_G1PrT9fMmPp59lfiwZy4aNyvW8jxfzldaDtqF6skUKlSgYw9k=d',
  small: 'https://lh3.googleusercontent.com/eeGHK-Jg0pl1VRgQYuisRH9ZTEgWTFjJ2178uPrrsEp4v_UDf4MYvMskg728ifPx-dzN6Pzai0pRM3P_P7m3opXHQZIT5B4NUabVEc09DXhUmlNXC17-N8OZHcVtjFcgBm4EwnoQAQU=d',
  width: 210,
  height: 280,
  caption: 'Tongue'
},
{
  big: 'https://lh3.googleusercontent.com/O7gGH2mcrSICK8K8r3k_O2ZPxBeonXImV-x30Kfww5pU8YMofPnWfFfyKIyAxQoQCN02CPChV3QKxL83CdqVmJli4Jr6g2lV6N8II9XGdadT1ZjvHxX-xCKhBvNfx9iZBVt8FPl2RZY=d',
  small: 'https://lh3.googleusercontent.com/5FwvJ5Fz5AJarVRm0MUu1l7CopgPxS4Cwu33IHWxD9uuh1702cI7D62kulwaxq72-mBEUAYI_hy1KRJx9hM9MmDXQOSYZ8_Y_3LRhUyWZeyrbgN9ZN5eRzc41P_lckFHZ9tHd8eEapQ=d',
  width: 233,
  height: 280,
  caption: 'Laughing'
},
{
  big: 'https://lh3.googleusercontent.com/Omoic-nyiDHaSCH_Ka8ic73FhdHmaYvloaXUFaPgRlS0Vba_ONHV9-iTfY3cz3_78zaMFJ4jGG4uUWScC8BY3MMHhv2s0xJJrPhUc0SP1hwvYfXINCCBZwZE90zjaksfEOO9BuLLDLM=d',
  small: 'https://lh3.googleusercontent.com/0s-9u5JL5Hs5l65zfsuqGCyIsyeGcwHVhLUVRCJlgoKRb9DzrF8BMdHFDa840FjFa70rWSv09h149a8xOfI2c5Y4hRU2ODH84WIBOFm3PtwImL-AmygsXv5CNhkWz64bmQV2QCbT5jI=d',
  width: 280,
  height: 210,
  caption: 'Yo'
},
{
  big: 'https://lh3.googleusercontent.com/6byu7D4aAZY_imIOIkYhpjri0Ctlp8ZuMMgYewrDYR84rbaegOCdzG_PDdwLAEvLxlZAn5P21m8L5pBFbX2POpMWFIgCnmZ_vlFN03Am4s4tjyFaGYCrHhoNw5lkOtPs2jApUwWPkbA=d',
  small: 'https://lh3.googleusercontent.com/92ix3DAPy-Zc4m3VIgcovP6h3p0GHivEvUwRz3_l_HZKJHBHfzZpsLLpEjVbXVdoMzqalkNGGEIuUn98kSf69iu1D2zqEDuv_tt_tEsQ6P1uz9TnkpUNKsboNjNhh8GFhYoeJ_m9O9E=d',
  width: 280,
  height: 209,
  caption: 'Views'
},

]

class Story extends Component {
  state = { ibis: false }

  handleClick = e => {
    e.preventDefault()
    this.setState(prevState => ({
      ...prevState,
      ibis: !this.state.ibis
    }))
  }

  render() {
    const { lang } = this.props
    const { ibis } = this.state
    return (
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Dancing+Script&display=swap" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet"></link>
        </Helmet>
        <Layout>
          <SEO title="Story" />
          <Container className='mt-5'>
            <Row>
              <Col>
                <Grid album={album} />
              </Col>
            </Row>
            <Row className={`${styles.main} mt-4`}>
              <Col>
                <ButtonGroup>
                  <Button style={{ backgroundColor: '#000080', fontSize: '1.2em' }} onClick={(e) => this.handleClick(e)} active={ibis}>{lang === 'english' ? 'By Ibis' : 'Por Ibis'}</Button>
                  <Button style={{ backgroundColor: '#000080', fontSize: '1.2em' }} onClick={(e) => this.handleClick(e)} active={!ibis}>{lang === 'english' ? 'By Hunt' : 'Por Hunt'}</Button>
                </ButtonGroup>

              </Col>
            </Row>
            <Row className={`${styles.main} mt-4`}>
              <Col>
                <div>{ibis ?
                  <>
                    <h1 style={{ marginBottom: '.8em' }}>{lang === 'english' ? 'Our Love Story by Ibis' : 'Nuestra Historia De Amor Por Ibis'}</h1>
                    <p className='mt-3'>{lang === 'english' ? "In the month of March 2018 I was on vacation in Miami. I decided to go to one of my favorite places, Bayside, a beautiful harbor full of restaurants, shops and live music. I was taking pictures, and a man wakled up to me to ask if he could. I felt a strong connection at first glance. I felt a strong attraction too. He asked me to dance, and we danced all night on the pier. The minutes were like seconds, we were talking and laughing as if we had known each other for a long time. As soon as I returned to Mexico we kept in touch."
                      : "En el mes de Marzo de 2018 estaba de vacaciones en Miami. Decidí a ir a uno de mis lugares favoritos, Bayside, un hermoso puerto lleno de restaurantes tiendas y música en vivo. Estaba buscando a alguien para tomarme una foto y lo encontré inmediatamente, sentí una fuerte conexión en la primer mirada que cruzamos, además de atracción por lo guapo que es. Después bailamos toda la noche en el muelle, los minutos eran como segundos, nos quedamos platicando y riendo como si lo hubiera conocido de hace tiempo. Apenas regresé a México nos mantuvimos en contacto."}</p>
                    <p>{lang === 'english' ? "In May 2018, Hunt came to visit me. We went to many different places like Tapijulapa, Palenque, the Cascadas de Roberto barrios and Yumka. In a short time I knew that he was the love of my life: his green eyes, his gaze, his tender smile, our connection. I realized that nobody had made me laugh like Hunt. He has a great personality and a big heart. He is nature lover and passionate about what he does. I love the way he treats his family, people and animals. We have some strange connections and tastes in common. For me, he is perfect. And I fell in love! He has taught me many great things, to enjoy everything I do, and how to be a better person. We are working on a special project together. And we are incredibly excited to build a life together! I'm looking foward to spending the rest of our lives together!"
                      : "En el mes de Mayo de 2018 Hunt vino a visitarme. Me visitó y fuimos a muchos lugares diferentes como Tapijulapa, Palenque, las Cascadas de Roberto barrios y Yumka. En poco tiempo supe que era el amor de mi vida, sus ojos verdes, su mirada, su sonrisa tierna, nuestra conexión. Me di cuenta que nadie me había hecho reír como Hunt. Tiene una gran personalidad, amante de la naturaleza, apasionado en lo que hace y amo la forma que el trata a su familia, a las personas y a los animales, tiene un gran corazón, además, en nuestros hobbies tenemos gustos raros en común. Para mí, el es perfecto. Me enamoré! Me ha enseñado muchas cosas nuevas e interesantes, a disfrutar todo lo que hago y a cómo ser una mejor persona. Sobre nuestros gustos en común tenemos muchos planes y además, estamos construyendo un negocio juntos muy interesante y el cual disfrutamos mucho! Estoy emocionada por compartir mi vida con el!"}</p>
                  </>
                  :
                  <>
                    <h1 style={{ marginBottom: '.8em' }}>{lang === 'english' ? 'Our Love Story by Hunt' : 'Nuestra Historia De Amor Por Hunt'}</h1>
                    <p>{lang === 'english' ? "While on vacation in Miami, I met the most incredible person. A beautiful woman asked me to take a photo of her. She had beautiful brown curly hair, golden skin, brown eyes, and an irrestible smile. I was happy to take as many photos as she wanted. Afterwords I asked her to dance, and to my surprise she accepted my invitation to dance. After a lot of dancing, we sat down and talked for a bit and then went our separate ways. After returning to Denver, we continued to talk regularly." : "Mientras estaba de vacaciones en Miami, conocí a la persona más increíble. Una mujer hermosa me pidió que le tomara una foto. Tenía un hermoso cabello castaño y rizado, piel bronceada, ojos cafés y una dulce sonrisa. Estaba feliz de estarle tomando fotos. Para mi sorpresa, acepto mi invitación a bailar. Fue muy divertido bailar con ella. Después de tanto bailar, nos quedamos platicando y luego nos separamos. Después de regresar a Denver, seguí en contacto con ella."}</p>
                    <p>{lang === 'english' ? "In May of 2018, I went to visit her for a week in her hometown of Villahermosa. Through this visit I got a chance to get to know Ibis and her family more. I fell in love with her goofy personality, warmth, and affection. I can remember the day like it was yesterday. We went on a day trip to Zumka, a small zoo outside of town. I didn't understand much from the Spanish speaking tour quide, but I was the happiest person alive. I had met my soulmate and my best friend. A year and half later I proposed to Ibis at that very same place! With her by my side I've become a better person. We have learned many things together about life, business, and what makes us happy. We currently have a new project we are working together which is fun and exciting. I'm counting down the days til our special day!" : "En mayo de 2018 fui a visitarla por 2 semanas a su ciudad natal de Villahermosa. A través de esta visita tuve la oportunidad de conocer más a Ibis y su familia y me enamoré. Me enamoré de su personalidad divertida, cálida y cariñosa. Puedo recordar el día como si fuera ayer. Nos fuimos de excursión al Yumka, un pequeño zoológico a las afueras de la ciudad. No entendí mucho de la gira del habla hispana, pero me di que yo era la persona más feliz del mundo. Conocí a mi alma gemela y mi mejor amiga. Un año y medio después, le propuse matrimonio a Ibis en ese mismo lugar. Con ella a mi lado, me convertí en una mejor persona. Hemos aprendido muchas cosas juntos sobre la vida, los negocios y lo que nos hace felices. Actualmente tenemos un nuevo proyecto en el que estamos trabajando juntos, que es divertido y emocionante. No puedo esperar a ver lo que depara el futuro para nosotros!"}</p>
                  </>
                }</div>
              </Col>
            </Row>
            <Row className={`${styles.main} mt-4`}>
              <Col>
                <Collapse />
              </Col>
            </Row>
          </Container>
        </Layout>
      </>

    )
  }

}


export default connect(state => ({
  lang: state.lang
}), null)(Story)